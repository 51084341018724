import React from 'react'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Header2 from '../components/Header2'
import { Input, useColorMode } from '@chakra-ui/react'
import { useDisclosure } from '@chakra-ui/react'
import { Bars3Icon, Cog6ToothIcon } from '@heroicons/react/24/outline'
import Templates from '../partials/Templates'
import Main2 from '../components/sidebar/Main2'
import Cookies from 'js-cookie'
import useTemplateData from '../components/TemplateData'

function Generator() {
  const router = useNavigate()

  useEffect(() => {
    const authToken = Cookies.get('token')
    if (!authToken) {
      router('/signin')
    }
  }, [])

  const [user, setUser] = useState(null)
  const [showDrawer, setShowDrawer] = useState(false)
  const [showDrawer2, setShowDrawer2] = useState(false)
  const [search, setSearch] = useState('')

  const { colorMode, toggleColorMode } = useColorMode()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [subscriptionDetails, setSubscriptionDetails] = useState(null)

  const { data, loading, error } = useTemplateData();

  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    if (data) {
      setFilteredData(data);
    }
  }, [data]); 
  
  const handleSearchChange = (e) => {
    setSearch(e.target.value)

    const filteredResult = {}

    Object.entries(data).forEach(([category, templates]) => {
      const filteredCategory = {}

      Object.entries(templates).forEach(([templateName, templateData]) => {
        const { id, desc, icon, plan } = templateData // Extract the id along with desc and icon

        if (
          templateName.toLowerCase().includes(e.target.value.toLowerCase()) ||
          desc.toLowerCase().includes(e.target.value.toLowerCase())
        ) {
          filteredCategory[templateName] = { id, desc, icon, plan } // Include the id in the filtered results
        }
      })

      if (Object.keys(filteredCategory).length > 0) {
        filteredResult[category] = filteredCategory
      }
    })

    setFilteredData(filteredResult)
  }
  return (
    <div className="h-[100vh] overflow-y-hidden">
      <Header2
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        setUser={setUser}
        current={2}
        user={user}
        subscriptionDetails={subscriptionDetails}
        setSubscriptionDetails={setSubscriptionDetails}
      />
      <div className={`flex pb-40 h-screen`}>
        <Main2
          showDrawer={showDrawer}
          user={user}
          router={router}
          setShowDrawer={setShowDrawer}
          toggleColorMode={toggleColorMode}
          data={data}
          setFilteredData={setFilteredData}
          search={search}
          setShowDrawer2={setShowDrawer2}
          showDrawer2={showDrawer2}
        />
        <main
          className={`z-1 pb-20 flex-1 mx-auto w-full ${
            !showDrawer2 ? 'md:pl-72' : ''
          } overflow-y-scroll h-screen`}
        >
          {/* Mobile Drawer */}
          <button
            className="z-50 md:hidden ml-auto px-3 py-2 rounded-md  focus:outline-none"
            onClick={() => setShowDrawer(!showDrawer)}
          >
            <Cog6ToothIcon className="h-6 w-6" />
          </button>
          <button
            className="z-50 md:hidden px-3 py-2 rounded-md  focus:outline-none flex-none ml-auto"
            onClick={onOpen}
          >
            <Bars3Icon className="h-6 w-6" />
          </button>
          <div className={`px-4 sm:px-6 lg:px-8`}>
            <div className="flex flex-col items-stretch flex-grow m-auto p-8">
              <div className="flex flex-col md:flex-row justify-between">
                <div>
                  <h1 className="text-lg font-semibold">
                    Choose Template Type
                  </h1>
                </div>

                <div className="mt-0">
                  <Input
                    value={search}
                    onChange={(e) => handleSearchChange(e)}
                    variant="filled"
                    placeholder="Search..."
                    width={'15rem'}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col md:flex-row m-auto p-8 ">
              <div className="w-full">
                <Templates
                  data={filteredData}
                  planId={subscriptionDetails?.planId}
                />
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}

export default Generator
