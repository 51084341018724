import React, { useState } from 'react'
import {
  Heading,
  Text,
  Card,
  CardHeader,
  CardBody,
  useColorModeValue,
  Divider,
  useToast,
} from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'

function Templates({ data, planId }) {
  console.log("data", data)
  const bg = useColorModeValue('#FFF2F2', '#1E293B')
  const router = useNavigate()
  const toast = useToast()
  // Use an array to store the hover state for each Card
  const [hoverStates, setHoverStates] = useState({})

  const handleMouseEnter = (id) => {
    setHoverStates((prevHoverStates) => ({ ...prevHoverStates, [id]: true }))
  }

  const handleMouseLeave = (id) => {
    setHoverStates((prevHoverStates) => ({ ...prevHoverStates, [id]: false }))
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-10">
      {Object.keys(data).map((category) =>
        Object.entries(data[category]).map(([templateName, templateData]) => {
          const { id, desc, icon, plan } = templateData

          const isHovered = hoverStates[id] || false // Get the hover state for the current Card

          return (
            <Card
              onClick={() =>
                planId && plan && plan.includes(planId)
                  ? router(`/template/${id}`)
                  : toast({
                      title: 'Please upgrade for this.',
                      status: 'error',
                      duration: 9000,
                      isClosable: true,
                    })
              }
              className="cursor-pointer relative"
              backgroundColor={
                planId && plan && plan.includes(planId) ? bg : 'gray.100'
              }
              textColor={
                planId && plan && plan.includes(planId) ? null : 'gray.500'
              }
              key={templateName}
              onMouseEnter={() => handleMouseEnter(id)} // Pass the id to the handler
              onMouseLeave={() => handleMouseLeave(id)} // Pass the id to the handler
              style={{
                boxShadow: isHovered ? '0 4px 8px rgba(0, 0, 0, 0.1)' : 'none',
                transform: isHovered ? 'scale(1.02)' : 'scale(1)',
                transition: 'box-shadow 0.3s ease, transform 0.3s ease',
              }}
            >
              <CardHeader>
                <Heading size="md">{templateName}</Heading>
              </CardHeader>

              <CardBody>
                <Text className="-mt-6" fontSize="sm">
                  {desc}
                </Text>
                <Divider className="my-4" />
              </CardBody>
            </Card>
          )
        }),
      )}
    </div>
  )
}

export default Templates
