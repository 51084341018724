import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import { Link } from 'react-router-dom'
import { useGoogleLogin } from '@react-oauth/google'
import { useEffect } from 'react'
import { Button, Input, useToast } from '@chakra-ui/react'
import { StarIcon } from '@heroicons/react/24/solid'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import Fiverr1 from '../images/Fiverr1.jpg'
import Fiverr2 from '../images/Fiverr2.jpg'
import Fiverr3 from '../images/Fiverr3.jpg'

import { FcGoogle } from 'react-icons/fc'
import { FaApple } from 'react-icons/fa'
import { FaFacebookSquare } from 'react-icons/fa'

function Signin() {
  const navigate = useNavigate()
  const toast = useToast()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [disabled, setDisabled] = useState(false)

  const slides = [
    {
      image: Fiverr1,
    },
    {
      image: Fiverr2,
    },
    {
      image: Fiverr3,
    },

    // Add more slides here
  ]

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000, // Animation speed
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000, // Slide change interval
    arrows: false,
  }

  useEffect(() => {
    const authToken = Cookies.get('token')
    if (authToken) {
      navigate('/dashboard')
    }
  }, [])

  const handleSignIn = async (e) => {
    e.preventDefault()

    if (email.includes('.') && email.includes('@') && password.length > 2) {
      setDisabled(true)
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/signin`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              email,
              password,
            }),
          },
        )

        const data = await response.json()

        if (data.error) {
          toast({
            title: data.error,
            status: 'error',
            duration: 9000,
            isClosable: true,
          })
        } else if (data.token) {
          Cookies.set('token', data.token, { expires: 7 })
          Cookies.set('email', data.email, { expires: 7 })
          setTimeout(() => {
            navigate('/dashboard')
          }, 2000)
        } else {
          toast({
            title: 'Something went wrong!',
            status: 'error',
            duration: 9000,
            isClosable: true,
          })
        }

        setDisabled(false)
      } catch (error) {
        toast({
          title: 'An error occured during signin',
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
        setDisabled(false)
      }
    } else {
      toast({
        title: 'Invalid email or password',
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  }

  const handleSubmitGoogle = async (googleAccessToken) => {
    setDisabled(true)
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/signin`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ googleAccessToken }),
        },
      )

      const data = await response.json()

      if (data.error) {
        toast({
          title: data.error,
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      } else if (data.token) {
        Cookies.set('token', data.token, { expires: 7 })
        Cookies.set('email', data.email, { expires: 7 })
        setTimeout(() => {
          navigate('/dashboard')
        }, 2000)
      } else {
        toast({
          title: 'Something went wrong!',
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      }

      setDisabled(false)
    } catch (error) {
      toast({
        title: 'An error occured during signin',
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
      setDisabled(false)
    }
  }

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      handleSubmitGoogle(tokenResponse.access_token)
    },
  })

  return (
    <section className="relative min-h-screen">
      <div className="mx-auto h-full bg-[#F4F4F4] border-b border-black  flex grid grid-cols-1 md:grid-cols-2">
        <div className="p-6 md:p-12 py-32 md:px-32 md:py-48 bg-[#adc4fe]">
          <div
            style={{ boxShadow: '5px 5px 0px 0px #003e39' }}
            className="max-w-6xl p-4  border-[#000] border-[1px] bg-[#f4f4f4] mx-auto flex-1 py-12 rounded-md"
          >
            {/* Page header */}
            <div className="mx-auto text-center pb-12 md:pb-20">
              <h1 className="font-extrabold text-5xl md:text-7xl">
                Enter Credentials
              </h1>
              <p>Please enter your login information below</p>
            </div>

            {/* Form */}
            <div className="max-w-sm mx-auto">
              <div className="flex flex-wrap -mx-3 mb-4">
                <div className="w-full px-3">
                  <Input
                    id="email"
                    type="email"
                    className="form-input w-full bg-white border-[1px] border-[#000]"
                    placeholder="Enter your email address"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    bgColor={'white'}
                    borderColor={'black'}
                    borderWidth={'1px'}
                  />
                </div>
              </div>
              <div className="flex flex-wrap -mx-3 mb-4">
                <div className="w-full px-3">
                  <Input
                    id="password"
                    type="password"
                    className="form-input w-full"
                    placeholder="Password (at least 10 characters)"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    bgColor={'white'}
                    borderColor={'black'}
                    borderWidth={'1px'}
                  />
                </div>
              </div>

              <div className="flex flex-wrap -mx-3 mt-6">
                <div className="w-full px-3">
                  <Button
                    disabled={disabled}
                    className="btn text-white bg-black w-full"
                    isLoading={disabled}
                    bg="black"
                    onClick={handleSignIn}
                    _hover={{ bg: 'black' }}
                  >
                    Sign in
                  </Button>
                </div>
              </div>
              <div className="border-t-[1px] border-[#000] pt-4 my-8">
                {' '}
                <div className="flex flex-wrap -mx-3 my-4">
                  <div className="w-full px-3">
                    <button
                      onClick={googleLogin}
                      className="btn px-0 text-black  bg-tranparent border-[1px] border-black font-bold w-full relative flex items-center rounded-md"
                    >
                      <FcGoogle className="flex w-5 h-5 justify-center align-center items-center mr-2" />
                      <span className="flex">Sign in with Google</span>
                    </button>
                  </div>
                </div>
              </div>

              <div className="flex flex-wrap my-12 mb-4">
                <div className="w-full px-3">
                  <div className="flex justify-between">
                    <Link
                      to="/reset-password"
                      className="text-black m-auto transition duration-150 ease-in-out underline"
                    >
                      Forgot Password?
                    </Link>
                  </div>
                </div>
              </div>
              <div className="text-black text-center mt-6">
                Don’t you have an account?{' '}
                <Link
                  to="/signup"
                  className="text-black underline transition duration-150 ease-in-out"
                >
                  Sign up
                </Link>
              </div>
            </div>
          </div>
          <p className="my-6">© 2024 ScribeFlows</p>
        </div>
        {/* Slideshow */}
        <div className="relative flex-1 text-center justify-center items-center my-auto">
          <div>
            {' '}
            <Slider className="flex-1 px-4 " {...settings}>
              {slides.map((slide, index) => (
                <div key={index}>
                  <div className="flex flex-col h-full space-y-4 justify-center items-center border-2 border-black rounded-lg">
                    {/* <div className="flex flex-row">
                      <StarIcon className="w-4 h-4" />
                      <StarIcon className="w-4 h-4" />
                      <StarIcon className="w-4 h-4" />
                      <StarIcon className="w-4 h-4" />
                      <StarIcon className="w-4 h-4" />
                    </div>
                    <h1 className="text-2xl font-bold">{slide.quote}</h1>
                    <div className="flex flex-row items-center space-x-4">
                      <div className="font-medium flex flex-row items-center text-left space-x-2">
                        <img
                          src="empty.jpeg"
                          className="rounded-full h-10 w-10"
                          alt={slide.name}
                        />
                        <div>
                          <p className="font-bold">{slide.name}</p>
                          <p>{slide.role}</p>
                        </div>
                      </div>
                      <div className="text-xl border-l border-[#000] p-4 font-bold">
                        {slide.company}
                      </div>
                    </div> */}
                    <img src={slide.image} height={'100%'} className='rounded-lg' />
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Signin
