import { useEffect, useState } from 'react';
import axios from 'axios';

function useTemplateData() {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/initialData`);

        console.log("response.data", response.data.data)
        setData(response.data.data); // Adjust based on your API response structure
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return { data, loading, error };
}

export default useTemplateData;
