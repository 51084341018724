import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import { loadStripe } from '@stripe/stripe-js'
import axios from 'axios'
import { Modal, ModalOverlay } from '@chakra-ui/react'
import { Spinner } from '@chakra-ui/react'
import { CubeIcon } from '@heroicons/react/24/outline'

import { PiSparkle, PiSparkleDuotone, PiSparkleFill } from 'react-icons/pi'

function PricingTables() {
  const [value, setValue] = useState(true)
  const [isLoading, setLoading] = useState(false)
  const authToken = Cookies.get('token')
  const router = useNavigate()
  const [stripe, setStripe] = useState(null)
  const [user, setUser] = useState()
  const [subscriptionDetails, setSubscriptionDetails] = useState()

  useEffect(() => {
    const fetchSubscriptionDetails = async () => {
      // Check if subscription details exist in localStorage
      const cachedSubscriptionDetails = localStorage.getItem(
        'subscriptionDetails',
      )
      if (cachedSubscriptionDetails) {
        const data = JSON.parse(cachedSubscriptionDetails)
        setSubscriptionDetails(data)
        setUser(data.user)

        setLoading(false)
      } else {
        // Fetch subscription details from the API
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/getSubscription`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          },
        )
        const data = await response.json()

        if (data.error === 'User not found!') {
          await Cookies.remove('token')
          await localStorage.removeItem('subscriptionDetails')
          await Cookies.remove('email')
          router('/signin')
        }
        setUser(data.user)
        setSubscriptionDetails(data)
        setLoading(false)

        // Cache the subscription details in localStorage
        localStorage.setItem('subscriptionDetails', JSON.stringify(data))
      }
    }

    fetchSubscriptionDetails()
  }, [authToken])

  useEffect(() => {
    const fetchStripe = async () => {
      const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLIC)
      setStripe(stripe)
    }
    fetchStripe()
  }, [])

  const handleManageSubscription = async () => {
    try {
      const cachedSubscriptionDetails = localStorage.getItem(
        'subscriptionDetails',
      )
      if (cachedSubscriptionDetails) {
        const data = JSON.parse(cachedSubscriptionDetails)
        if (data.planId === 'Free Plan') {
          router('/pricing')
        } else {
          const response = await axios.post(
            `${
              process.env.REACT_APP_BACKEND_URL
            }/api/createCustomerPortalSession`,
            {},
            {
              headers: {
                Authorization: `Bearer ${authToken}`,
              },
            },
          )
          await Cookies.remove('token')
          await localStorage.removeItem('subscriptionDetails')
          window.location.href = response.data.url
        }
      }
    } catch (error) {
      console.error(error)
    }
  }
  const createSubscription = async (priceId) => {
    try {
      const cachedSubscriptionDetails = localStorage.getItem(
        'subscriptionDetails',
      )
      if (cachedSubscriptionDetails) {
        const data = JSON.parse(cachedSubscriptionDetails)
        if (data.planId === 'Free Plan') {
          router('/pricing')
        }
      }
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/createCheckoutSession`,
        {
          priceId,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        },
      )
      await localStorage.removeItem('subscriptionDetails')
      stripe.redirectToCheckout({ sessionId: response.data.sessionId })
    } catch (error) {
      router('/signin')
      console.error(error)
      return { error: error.message }
    }
  }

  const [priceOutput] = useState({
    plan2: {
      true: ['$', '49.99', '/Monthly'],
    },
    plan3: {
      true: ['$', '499.99', '/Yearly'],
    },
  })

  return (
    <>
      <Modal isCentered isOpen={isLoading}>
        <ModalOverlay
          bg="blackAlpha.300"
          backdropFilter="blur(10px) hue-rotate(90deg)"
          className="items-center flex justify-center"
        >
          <Spinner size="xl" />
        </ModalOverlay>
      </Modal>
      <section className="relative bg-[#FEEECA]   min-h-screen">
        <div className="max-w-6xl mx-auto px-4 py-24 sm:px-6 py-20 sm:pt-48 sm:pb-48 ">
          <div className="py-12 md:py-28">
            {/* Section header */}
            <div className="mx-auto text-center pb-12 space-y-4">
              <p className="font-bold">Affordable</p>
              <h1
                className="font-extrabold text-5xl md:text-7xl mb-4"
                data-aos="fade-up"
              >
                Pricing Plans
              </h1>
              <p
                data-aos="fade-up"
                data-aos-delay="200"
                style={{ fontSize: '1.2em' }}
              >
                Choose the pricing plan that suits your needs
              </p>
            </div>

            {/* Pricing tables */}
            <div>
              {/* Pricing toggle */}
              <div
                className="flex justify-center mb-8"
                data-aos="fade-up"
                data-aos-delay="400"
              ></div>

              <div className="max-w-sm mx-auto grid gap-8 lg:grid-cols-2 lg:gap-6 items-start lg:max-w-none">
                {/* Pricing table 2 */}
                <div
                  className="relative flex flex-col h-full p-6 bg-white border-[1px] border-[#000] rounded-md"
                  data-aos="fade-up"
                  data-aos-delay="700"
                  style={{ boxShadow: '5px 5px 0px 0px #003e39' }}
                >
                  <PiSparkleDuotone
                    className="w-8 h-8 flex ml-auto flex-end mb-6"
                    style={{ color: 'green' }}
                  />
                  <div className="mb-4 pb-4 border-b border-gray-700">
                    <div className="font-bold text-black mb-1">
                      Monthly Plan
                    </div>
                    <div className="inline-flex items-baseline mb-2">
                      <span className="h2">{priceOutput.plan2[value][0]}</span>
                      <span className="h2">{priceOutput.plan2[value][1]}</span>
                      <span className="h2">{priceOutput.plan2[value][2]}</span>
                    </div>
                  </div>
                  <div className="mt-6  rounded-md">
                    <a
                      className={`btn-sm  rounded-md text-white bg-black p-4 hover:bg-purple-700 w-full`}
                      onClick={(e) => {
                        e.preventDefault()
                        user
                          ? createSubscription(
                              process.env.REACT_APP_MONTHLY_PRICE_ID,
                            )
                          : router('/signin')
                      }}
                    >
                      Subscribe Monthly
                    </a>
                  </div>
                </div>

                {/* Pricing table 3 */}
                <div
                  className="relative flex flex-col h-full p-6 bg-white border-[1px] border-[#000] rounded-md"
                  data-aos="fade-up"
                  data-aos-delay="700"
                  style={{ boxShadow: '5px 5px 0px 0px #003e39' }}
                >
                  <PiSparkleFill
                    className="w-8 h-8 flex ml-auto flex-end mb-6"
                    style={{ color: 'gold' }}
                  />
                  <div className="mb-4 pb-4 border-b border-gray-700">
                    <div className="font-bold text-black mb-1">Yearly Plan</div>
                    <div className="inline-flex items-baseline mb-2">
                      <span className="h2">{priceOutput.plan3[value][0]}</span>
                      <span className="h2">{priceOutput.plan3[value][1]}</span>
                      <span className="h2">{priceOutput.plan3[value][2]}</span>
                    </div>
                  </div>
                  <div className="mt-6  rounded-md">
                    <a
                      className={`btn-sm  rounded-md text-white  bg-black p-4  hover:bg-purple-700 w-full`}
                      onClick={(e) => {
                        e.preventDefault()
                        user
                          ? createSubscription(
                              process.env.REACT_APP_YEARLY_PRICE_ID,
                            )
                          : router('/signin')
                      }}
                    >
                      Subscribe Yearly
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>{' '}
    </>
  )
}

export default PricingTables
