import React, { useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Input,
} from '@chakra-ui/react'

const Popup = ({ title, inputs, onCancel, onConfirm }) => {
  // Initialize an object to store the values of each input field
  const [inputValues, setInputValues] = useState({})

  // Handle individual input change
  const handleInputChange = (event, inputField) => {
    setInputValues({
      ...inputValues,
      [inputField]: event.target.value,
    })
  }

  // Handle the confirm button click
  const handleConfirm = () => {
    // Check if any input field value is empty
    // Pass all input field values to the onConfirm function
    onConfirm(inputValues)
    setInputValues({}) // Clear input values after confirming
  }

  return (
    <Modal  isOpen={true} onClose={onCancel} isCentered>
      <ModalOverlay />
      <ModalContent
      bgColor={"#FEEECA"}
        className="border-black border-[1px] font-[MyFont] py-24 m-auto justify-center align-center items-center bg-[#FEEECA]"
        style={{ boxShadow: '5px 5px 0px 0px #003e39' }}
      >
        <p className='text-4xl font-extrabold'>{title}</p>
        <ModalCloseButton />
        <ModalBody pb={6}>
          {inputs?.map((inputField, index) => (
            <FormControl key={index} mb={4}>
              <FormLabel>{inputField}</FormLabel>
              <Input
                value={inputValues[inputField] || ''}
                onChange={(event) => handleInputChange(event, inputField)}
                type={
                  inputField === 'Password'
                    ? 'password'
                    : inputField === 'OTP'
                    ? 'number'
                    : 'text'
                }
                borderColor={"black"}
                borderWidth={"1px"}
              />
            </FormControl>
          ))}
        </ModalBody>
        <ModalFooter>
          <Button
            bgColor={'black'}
            className="text-white"
            _hover={{ bg: 'black' }}
            mr={3}
            onClick={handleConfirm}
          >
            Confirm
          </Button>
          <Button
            bgColor={'black'}
            className="text-white"
            _hover={{ bg: 'black' }}
            onClick={onCancel}
          >
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default Popup
