import React, { useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import Cookies from 'js-cookie'
import { useGoogleLogin } from '@react-oauth/google'
import { Button, Input, useToast } from '@chakra-ui/react'

import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { CubeIcon } from '@heroicons/react/24/outline'
import Popup from '../partials/Popup'

import Fiverr1 from '../images/Fiverr1.jpg'
import Fiverr2 from '../images/Fiverr2.jpg'
import Fiverr3 from '../images/Fiverr3.jpg'

import { FcGoogle } from 'react-icons/fc'
import { FaApple } from 'react-icons/fa'
import { FaFacebookSquare } from 'react-icons/fa'

import Swal from 'sweetalert2'

function Signup() {
  const router = useNavigate()
  const toast = useToast()

  const [disabled, setDisabled] = React.useState(false)
  const [data, setData] = React.useState({
    fName: '',
    lName: '',
    email: '',
    password: '',
    companyName: '',
  })
  const [isPopupOpen, setPopupOpen] = useState(false)
  const slides = [
    {
      image: Fiverr1,
    },
    {
      image: Fiverr2,
    },
    {
      image: Fiverr3,
    },
    // Add more slides here
  ]

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000, // Animation speed
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000, // Slide change interval
    arrows: false,
  }

  const authToken = Cookies.get('token')
  if (authToken) {
    router('/dashboard')
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (
      data.email.includes('.') &&
      data.email.includes('@') &&
      data.fName.length > 2 &&
      data.lName.length > 1 &&
      data.password.length > 2
    ) {
      setDisabled(true)
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/signup`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            fName: data.fName,
            lName: data.lName,
            email: data.email,
            password: data.password,
            companyName: data.companyName,
          }),
        },
      )

      const res2 = await res.json()

      if (res2.error) {
        toast({
          title: res2.error,
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      } else if (res2.message) {
        toast({
          title: 'OTP Sent!',
          status: 'success',
          duration: 9000,
          isClosable: true,
        })
        setPopupOpen(true)
      } else {
        toast({
          title: 'Something went wrong!',
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      }
      setDisabled(false)
    } else {
      toast({
        title: 'Something went wrong!',
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  }

  const handleSubmitGoogle = async (googleAccessToken) => {
    setDisabled(true)
    const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/signup`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ googleAccessToken }),
    })

    const res2 = await res.json()

    if (res2.error) {
      toast({
        title: res2.error,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    } else if (res2.message) {
      toast({
        title: 'User Created!',
        status: 'success',
        duration: 9000,
        isClosable: true,
      })
      setTimeout(() => {
        router('/signin')
      }, 2000)
    } else {
      toast({
        title: 'Something went wrong!',
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
    setDisabled(false)
  }

  const handleChange = (e) => {
    const newdata = { ...data }
    newdata[e.target.id] = e.target.value
    setData(newdata)
  }

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      handleSubmitGoogle(tokenResponse.access_token)
    },
    // flow: 'implicit', // implicit is the default
  })

  return (
    <>
      <section className="relative">
        <div className="mx-auto h-full bg-[#F4F4F4] border-b border-black  flex grid grid-cols-1 md:grid-cols-2">
          <div className="p-6 md:p-12 py-32 md:px-32 md:py-48 bg-[#adc4fe]">
            <div
              style={{ boxShadow: '5px 5px 0px 0px #003e39' }}
              className="max-w-6xl p-4 border-[1px] border-[#000] bg-[#f4f4f4] rounded-md mx-auto flex-1 py-12"
            >
              {/* Page header */}
              <div className="max-w-3xl mx-auto text-center pb-12">
                <h1 className="font-extrabold text-5xl md:text-7xl">
                  Get Started
                </h1>
                <p>Join our community and unlock exclusive benefits.</p>
              </div>

              {/* Form */}
              <div className="max-w-sm mx-auto">
                <div className="flex flex-wrap -mx-3 mb-4">
                  <div className="w-full px-3">
                    <Input
                      id="fName"
                      type="text"
                      className="form-input w-full"
                      placeholder="First name"
                      required
                      onChange={handleChange}
                      bgColor={'white'}
                      borderColor={'black'}
                      borderWidth={'1px'}
                    />
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-4">
                  <div className="w-full px-3">
                    <Input
                      id="lName"
                      type="text"
                      className="form-input w-full"
                      placeholder="Last name"
                      required
                      onChange={handleChange}
                      bgColor={'white'}
                      borderColor={'black'}
                      borderWidth={'1px'}
                    />
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-4">
                  <div className="w-full px-3">
                    <Input
                      id="companyName"
                      type="text"
                      className="form-input w-full"
                      placeholder="Your company or app name"
                      required
                      onChange={handleChange}
                      bgColor={'white'}
                      borderColor={'black'}
                      borderWidth={'1px'}
                    />
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-4">
                  <div className="w-full px-3">
                    <Input
                      id="email"
                      type="email"
                      className="form-input w-full"
                      placeholder="Enter your email address"
                      required
                      onChange={handleChange}
                      bgColor={'white'}
                      borderColor={'black'}
                      borderWidth={'1px'}
                    />
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-4">
                  <div className="w-full px-3">
                    <Input
                      id="password"
                      type="password"
                      className="form-input w-full"
                      placeholder="Password (at least 10 characters)"
                      required
                      value={data.password}
                      onChange={handleChange}
                      bgColor={'white'}
                      borderColor={'black'}
                      borderWidth={'1px'}
                    />
                  </div>
                </div>

                <div className="flex flex-wrap -mx-3 mt-6">
                  <div className="w-full px-3">
                    <Button
                      className="btn text-white rounded-md bg-black w-full"
                      bg="black"
                      disabled={disabled}
                      onClick={handleSubmit}
                      isLoading={disabled}
                      _hover={{ bg: 'black' }}
                    >
                      Sign Up
                    </Button>
                  </div>
                </div>
                <div className="border-t-[1px] border-[#000] pt-4 my-8">
                  {' '}
                  <div className="flex flex-wrap -mx-3 my-4">
                    <div className="w-full px-3">
                      <button
                        onClick={googleLogin}
                        className="btn px-0 text-black  bg-tranparent border-[1px] border-black font-bold w-full relative flex items-center rounded-md"
                      >
                        <FcGoogle className="flex w-5 h-5 justify-center align-center items-center mr-2" />
                        <span className="flex">Sign up with Google</span>
                      </button>
                    </div>
                  </div>
                </div>

                <div className="text-black text-center mt-6">
                  Already have an account{' '}
                  <Link
                    to="/signin"
                    className="text-black underline transition duration-150 ease-in-out"
                  >
                    Log In
                  </Link>
                </div>
              </div>
            </div>
            <p className="my-6">© 2024 ScribeFlows</p>
          </div>
          {/* Slideshow */}
          <div className="relative flex-1 text-center justify-center items-center my-auto">
            <div>
              {' '}
              <Slider className="flex-1 px-4 " {...settings}>
                {slides.map((slide, index) => (
                  <div key={index}>
                    <div className="flex flex-col h-full space-y-4 justify-center items-center  border-2 border-black rounded-lg">
                      {/* <div className="flex flex-row">
                        <StarIcon className="w-4 h-4" />
                        <StarIcon className="w-4 h-4" />
                        <StarIcon className="w-4 h-4" />
                        <StarIcon className="w-4 h-4" />
                        <StarIcon className="w-4 h-4" />
                      </div>
                      <h1 className="text-2xl font-bold">{slide.quote}</h1>
                      <div className="flex flex-row items-center space-x-4">
                        <div className="font-medium flex flex-row items-center text-left space-x-2">
                          <img
                            src="empty.jpeg"
                            className="rounded-full h-10 w-10"
                            alt={slide.name}
                          />
                          <div>
                            <p className="font-bold">{slide.name}</p>
                            <p>{slide.role}</p>
                          </div>
                        </div>
                        <div className="text-xl border-l border-[#000] p-4 font-bold">
                          {slide.company}
                        </div>
                      </div> */}
                      <img src={slide.image} height={'100%'} />
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </section>
      {isPopupOpen && (
        <Popup
          title="Submit your OTP"
          inputs={['OTP']}
          onCancel={() => setPopupOpen(false)}
          onConfirm={async (values) => {
            const { OTP: otp } = values
            fetch(`${process.env.REACT_APP_BACKEND_URL}/api/verify`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                otp,
                email: data.email,
              }),
            })
              .then((response) => response.json())
              .then((res) => {
                if (res.message) {
                  toast({
                    title: res.message,
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                  })
                  setTimeout(() => {
                    router('/signin')
                  }, 2000)
                } else if (res.error) {
                  toast({
                    title: res.error,
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                  })
                }
              })
              .catch((error) => {
                Swal.showValidationMessage(`Request failed: ${error}`)
              })

            setPopupOpen(false)
          }}
        />
      )}
    </>
  )
}

export default Signup
