import React, { useEffect } from 'react'

import { Routes, Route, useLocation } from 'react-router-dom'
import Signup from './pages/Signup'

import 'aos/dist/aos.css'

import AOS from 'aos'
import Signin from './pages/Signin'
import ResetPassword from './pages/ResetPassword'
import Generator from './pages/Generator'
import Template from './pages/Template'
import PricingTables from './pages/PricingTables'
import Admin from './pages/Admin'
import AdminSignin from './pages/AdminSignin'

function App() {
    const location = useLocation()

  useEffect(() => {
    AOS.init({
      once: true,
      disable: 'phone',
      duration: 600,
      easing: 'ease-out-sine',
    })
  })

  useEffect(() => {
    document.querySelector('html').style.scrollBehavior = 'auto'
    window.scroll({ top: 0 })
    document.querySelector('html').style.scrollBehavior = ''
  }, [location.pathname]) // triggered on route change
  return (
    <>
      <Routes>
        
          <Route path="/signin" element={<Signin />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/dashboard" element={<Generator />} />
          <Route path="/pricing" element={<PricingTables />} />
          <Route path="/template/:id" element={<Template />} />

          <Route path="/admin/signin" element={<AdminSignin />} />
          <Route path="/admin/dashboard" element={<Admin />} />

      </Routes>
    </>
  )
}

export default App
