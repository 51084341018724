import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  Textarea,
  Text,
  VStack,
  IconButton,
  Divider,
  useToast
} from "@chakra-ui/react";
import { DeleteIcon, AddIcon, MinusIcon } from "@chakra-ui/icons";
import Cookies from 'js-cookie'
import AdminNavbar from "../components/AdminNavbar";
import { useNavigate } from "react-router-dom";

const PromptForm = ({ promptData, onChange, onDelete }) => {
  const [inputFields, setInputFields] = useState(promptData?.inputs[0] || {});

  const handleInputChange = (key, fieldKey, e) => {
    const { name, value } = e.target;
    const updatedInputs = { ...inputFields };
    updatedInputs[key][name] = value;
    setInputFields(updatedInputs);
    onChange({ ...promptData, inputs: [updatedInputs] });
  };

  const handleAddInputField = () => {
    const newKey = `Field ${Object.keys(inputFields).length + 1}`;
    const updatedInputs = {
      ...inputFields,
      [newKey]: { type: "textarea", placeholder: "" },
    };
    setInputFields(updatedInputs);
    onChange({ ...promptData, inputs: [updatedInputs] });
  };

  const handleRemoveInputField = (key) => {
    const updatedInputs = { ...inputFields };
    delete updatedInputs[key];
    setInputFields(updatedInputs);
    onChange({ ...promptData, inputs: [updatedInputs] });
  };

  const handleKeyChange = (oldKey, newKey) => {
    if (oldKey === newKey) return;
    const updatedInputs = { ...inputFields };
    updatedInputs[newKey] = updatedInputs[oldKey];
    delete updatedInputs[oldKey];
    setInputFields(updatedInputs);
    onChange({ ...promptData, inputs: [updatedInputs] });
  };

  return (
    <Box mb={4} p={4} border="1px" borderColor="gray.200" borderRadius="md">
      <VStack spacing={4}>
        <FormControl>
          <FormLabel>ID</FormLabel>
          <Input
            name="id"
            value={promptData.id}
            onChange={(e) => onChange({ ...promptData, id: e.target.value })}
            isDisabled
          />
        </FormControl>
        <FormControl>
          <FormLabel>Description</FormLabel>
          <Input
            name="desc"
            value={promptData.desc}
            onChange={(e) => onChange({ ...promptData, desc: e.target.value })}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Header</FormLabel>
          <Input
            name="header"
            value={promptData.header}
            onChange={(e) => onChange({ ...promptData, header: e.target.value })}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Prompt</FormLabel>
          <Textarea
            name="prompt"
            value={promptData.prompt}
            onChange={(e) => onChange({ ...promptData, prompt: e.target.value })}
            rows={4}
          />
        </FormControl>
        {Object.entries(inputFields).map(([key, input], i) => (
          <Box key={key} w="100%" mb={4}>
            <Text fontSize="lg" mb={2}>Input {i + 1}</Text>
            <FormControl>
              <FormLabel>Field Key</FormLabel>
              <Input
                value={key}
                onChange={(e) => handleKeyChange(key, e.target.value)}
              />
            </FormControl>
            <FormControl mt={2}>
              <FormLabel>Type</FormLabel>
              <Select
                name="type"
                value={input.type}
                onChange={(e) => handleInputChange(key, "type", e)}
              >
                <option value="textarea">Textarea</option>
                <option value="file">File</option>
              </Select>
            </FormControl>
            <FormControl mt={2}>
              <FormLabel>Placeholder</FormLabel>
              <Input
                name="placeholder"
                value={input.placeholder}
                onChange={(e) => handleInputChange(key, "placeholder", e)}
              />
            </FormControl>
            <IconButton
              mt={2}
              icon={<MinusIcon />}
              onClick={() => handleRemoveInputField(key)}
              colorScheme="red"
            />
          </Box>
        ))}
        <Button
          onClick={handleAddInputField}
          leftIcon={<AddIcon />}
          colorScheme="blue"
        >
          Add Input Field
        </Button>
        <IconButton
          icon={<DeleteIcon />}
          onClick={onDelete}
          colorScheme="red"
        />
      </VStack>
    </Box>
  );
};

const Admin = () => {
  const [prompts, setPrompts] = useState([]);

  const toast = useToast()

  useEffect(() => {
    console.log("Fetching...", `${process.env.REACT_APP_BACKEND_URL}/api/initialData`)
    const fetchPrompts = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/initialData`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${Cookies.get('adminToken')}`,
          },
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const {data} = await response.json();
        console.log(data)
        setPrompts(Object.entries(data.Category).map(([key, value]) => value));
      } catch (error) {
        console.error('Failed to fetch prompts:', error);
      }
    };

    fetchPrompts();
  }, []);

  const handleAddPrompt = () => {
    const newPrompt = {
      id: prompts.length + 1,
      desc: "",
      icon: "",
      header: "",
      inputs: {},
      prompt: "",
      plan: [],
    };
    setPrompts([...prompts, newPrompt]);
  };

  const handlePromptChange = (index, updatedPrompt) => {
    const updatedPrompts = prompts.map((prompt, i) =>
      i === index ? updatedPrompt : prompt
    );
    setPrompts(updatedPrompts);
  };

  const handlePromptDelete = (index) => {
    const updatedPrompts = prompts.filter((_, i) => i !== index);
    setPrompts(updatedPrompts);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/initialData`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get('adminToken')}`,
        },
        body: JSON.stringify({ Category: prompts }),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const result = await response.json();
      toast({
        title: 'Success',
        description: 'Prompts updated successfully!',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error updating prompts:", error);
      toast({
        title: 'Error',
        description: `Error: ${error.message}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  };
  const navigate = useNavigate()

  useEffect(() => {
    const authToken = Cookies.get('adminToken');
    if (!authToken) {
      navigate('/admin/signin'); // Redirect to admin dashboard if already logged in
    }
  }, [navigate]);

  return (
    <Box>
       <AdminNavbar />
    
    <Box p={4}>
      <Text fontSize="2xl" mb={4}>
        Prompts Management
      </Text>
      <form onSubmit={handleSubmit}>
        {prompts.map((prompt, index) => (
          <PromptForm
            key={index}
            promptData={prompt}
            onChange={(updatedPrompt) => handlePromptChange(index, updatedPrompt)}
            onDelete={() => handlePromptDelete(index)}
          />
        ))}
        <Button
          onClick={handleAddPrompt}
          leftIcon={<AddIcon />}
          colorScheme="blue"
          mb={4}
        >
          Add Prompt
        </Button>
        <Button type="submit" colorScheme="green">
          Save All Prompts
        </Button>
      </form>
    </Box>
    </Box>
  );
};

export default Admin;
