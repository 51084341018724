import React, { useState } from 'react'
import LoadingDots from '../other/LoadingDots'
import { PaperAirplaneIcon } from '@heroicons/react/24/solid'
import { useColorModeValue } from '@chakra-ui/react'

const TemplateChatForm = ({
  loading,
  error,
  query,
  textAreaRef,
  handleEnter,
  handleSubmit,
  setQuery,
}) => {
  const bg = useColorModeValue('bg-white', 'bg-slate-800')

  return (
    <div className="flex flex-col space-y-1 m-auto p-1 ">
      <form
        onSubmit={handleSubmit}
        className="flex border rounded-md ring-[0.5px] ring-black"
      >
        <textarea
          disabled={loading}
          onKeyDown={handleEnter}
          ref={textAreaRef}
          autoFocus={false}
          rows={1}
          maxLength={512}
          id="userInput"
          name="userInput"
          placeholder={
            loading
              ? 'Waiting for response...'
              : 'Give me a question to answer...'
          }
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          className={`flex-1 p-1 md:p-2 border-none rounded-md  focus:ring-0  resize-none h-[35px] md:h-[50px] ${bg}`}
        />

        <button
          type="submit"
          disabled={loading}
          className={`text-[#5D5DFF] py-2 px-4 rounded-md ${bg}`}
        >
          {loading ? (
            <div>
              <LoadingDots color="#ff7900" />
            </div>
          ) : (
            <PaperAirplaneIcon className="h-6 w-6 -rotate-[35deg]" />
          )}
        </button>
      </form>
      {error && (
        <div className="border border-red-400 rounded-md p-4">
          <p className="text-red-500">{error}</p>
        </div>
      )}
    </div>
  )
}

export default TemplateChatForm
