import React, { useState, useEffect } from 'react'
import { Portal } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import { useColorModeValue } from '@chakra-ui/react'
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Modal,
  ModalOverlay,
  Button,
} from '@chakra-ui/react'
import { UserCircleIcon } from '@heroicons/react/24/solid'
import { Spinner } from '@chakra-ui/react'
import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'
import { ChevronDownIcon } from '@heroicons/react/24/outline'

function Header2({
  user,
  setUser,
  isOpen,
  onOpen,
  onClose,
  current,
  subscriptionDetails,
  setSubscriptionDetails,
}) {
  const [isLoading, setLoading] = useState(true)
  const authToken = Cookies.get('token')
  const router = useNavigate()

  useEffect(() => {
    const fetchSubscriptionDetails = async () => {
      // Check if subscription details exist in localStorage
      const cachedSubscriptionDetails = localStorage.getItem(
        'subscriptionDetails',
      )
      if (cachedSubscriptionDetails) {
        const data = JSON.parse(cachedSubscriptionDetails)
        setSubscriptionDetails(data)
        setUser(data.user)

        setLoading(false)
      } else {
        // Fetch subscription details from the API
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/getSubscription`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          },
        )
        const data = await response.json()

        if (data.error === 'User not found!') {
          await Cookies.remove('token')
          await localStorage.removeItem('subscriptionDetails')
          await Cookies.remove('email')
          router('/signin')
        }
        setUser(data.user)
        setSubscriptionDetails(data)
        setLoading(false)

        // Cache the subscription details in localStorage
        localStorage.setItem('subscriptionDetails', JSON.stringify(data))
      }
    }

    fetchSubscriptionDetails()
  }, [authToken])
  if (!authToken) {
    router('/signin')
  }

  const bg = useColorModeValue('bg-white', 'bg-slate-800')

  const handleLinkClick = (index) => {
    onClose()
  }

  return (
    <>
      <Modal isCentered isOpen={isLoading}>
        <ModalOverlay
          bg="blackAlpha.300"
          backdropFilter="blur(10px) hue-rotate(90deg)"
          className="items-center flex justify-center"
        >
          <Spinner size="xl" />
        </ModalOverlay>
      </Modal>
      {/* Header */}
      <Box
        display={{ base: 'none', md: 'block' }}
        style={{ zIndex: 60 }}
        className={`z-60 w-full ${bg} h-18 shrink-0 flex items-center sticky top-0 border-[#CEC7C7] border-b-2`}
      >
        {/* Menu */}
        <div className="flex-1 flex relative">
          <div
            href="/templates"
            onClick={() => handleLinkClick(2)}
            className="text-[#C5D2DC] px-2 w-[19.1rem] flex py-2 pr-2 border-[#CEC7C7] border-r-2"
          >
            <a
              className={`px-5 py-2 font-medium rounded-md flex gap-2 ${
                current === 2 ? 'text-[#5D5DFF]' : ''
              }`}
            >
              Templates
            </a>
          </div>

          {/* User */}
          <div className="lg:ml-10 flex flex-row">
            {user && user.avatar && user.avatar.length > 10 ? (
              <img
                alt="Avatar"
                className="rounded-full my-2 mx-4 h-[3rem] w-[3rem]"
                src={user.avatar}
              />
            ) : (
              <UserCircleIcon className="my-2 mx-4 h-[3rem] w-[3rem]" />
            )}
          </div>

          {/* Actions */}
          <div className="ml-auto  z-60 inline-flex items-center justify-center mr-10 lg:gap-x-4">
            <div href="/pricing" className="">
              <a
                href="/pricing"
                className={`px-5 py-2 font-medium rounded-md flex gap-2 text-[#5D5DFF]`}
              >
                Pricing
              </a>
            </div>
            {/* User Name */}
            <button className="inline-flex items-center text-[#767676] justify-center text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background bg-secondary text-secondary-foreground hover:bg-secondary/80 h-9 rounded-md px-4">
              {subscriptionDetails?.user.fName +
                ' ' +
                subscriptionDetails?.user.lName}
            </button>
          </div>
        </div>

        {/* Mobile Drawer */}
        <button
          className="md:hidden ml-auto px-3 py-2 rounded-md bg-gray-200 text-gray-800 focus:outline-none"
          onClick={onOpen}
        >
          Menu
        </button>
      </Box>

      {/* Drawer */}
      <Drawer placement="right" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerBody>
            <Box py={2}>
              <a
                className={`px-4 py-2 font-medium rounded-md flex gap-2 ${
                  current === 2 ? 'text-[#5D5DFF]' : ''
                }`}
                href="/templates"
                onClick={() => handleLinkClick(2)}
              >
                Templates
              </a>

              {/* User Name */}
              {/* Plan ID */}
              <button className="px-4 py-2 font-medium rounded-md flex gap-2 hover:text-[#767676]">
                {subscriptionDetails?.user.fName +
                  ' ' +
                  subscriptionDetails?.user.lName}
              </button>
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export default Header2
