import React, { useEffect } from 'react'
import {
  Box,
  Checkbox,
  CheckboxGroup,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  useColorModeValue,
} from '@chakra-ui/react'
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  Cog6ToothIcon,
} from '@heroicons/react/24/outline'
import { BanknotesIcon } from '@heroicons/react/20/solid'
import { ArrowRightOnRectangleIcon } from '@heroicons/react/20/solid'
import Cookies from 'js-cookie'
import { useState } from 'react'
import TemplatesSidebar from '../TemplatesSidebar'
import axios from 'axios'
function Main2({
  showDrawer,
  user,
  router,
  setShowDrawer,
  toggleColorMode,
  data,
  setFilteredData,
  search,
  selected,
  setShowDrawer2,
  showDrawer2,
}) {
  const [selectedCategories, setSelectedCategories] = useState([])

  useEffect(() => {
    if (selected) {
      setSelectedCategories(selected)
    } else {
      const filteredTemplates = Object.entries(data).filter(([category]) =>
        selectedCategories.includes(category),
      )

      const filteredResult = {}
      filteredTemplates.forEach(([category, templates]) => {
        const filteredCategory = {}
        Object.entries(templates).forEach(([templateName, templateData]) => {
          const { id, desc, icon, plan } = templateData

          if (
            templateName.toLowerCase().includes(search.toLowerCase()) ||
            desc.toLowerCase().includes(search.toLowerCase())
          ) {
            filteredCategory[templateName] = { id, desc, icon, plan }
          }
        })
        if (Object.keys(filteredCategory).length > 0) {
          filteredResult[category] = filteredCategory
        }
      })

      if (selectedCategories.length === 0) {
        setFilteredData(data)
      } else {
        setFilteredData(filteredResult)
      }
    }
  }, [selectedCategories, selected])

  const authToken = Cookies.get('token')
  const handleManageSubscription = async () => {
    try {
      const cachedSubscriptionDetails = localStorage.getItem(
        'subscriptionDetails',
      )
      if (cachedSubscriptionDetails) {
        const data = JSON.parse(cachedSubscriptionDetails)
        if (data.planId === 'Free Plan') {
          router('/pricing')
        } else {
          const response = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/api/createCustomerPortalSession`,
            {},
            {
              headers: {
                Authorization: `Bearer ${authToken}`,
              },
            },
          )
          await Cookies.remove('token')
          await localStorage.removeItem('subscriptionDetails')
          window.location.href = response.data.url
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleCategoryChange = (selected) => {
    setSelectedCategories(selected)

    const filteredTemplates = Object.entries(data).filter(([category]) =>
      selected.includes(category),
    )

    const filteredResult = {}
    filteredTemplates.forEach(([category, templates]) => {
      const filteredCategory = {}
      Object.entries(templates).forEach(([templateName, templateData]) => {
        const { id, desc, icon, plan } = templateData

        if (
          templateName.toLowerCase().includes(search.toLowerCase()) ||
          desc.toLowerCase().includes(search.toLowerCase())
        ) {
          filteredCategory[templateName] = { id, desc, icon, plan }
        }
      })
      if (Object.keys(filteredCategory).length > 0) {
        filteredResult[category] = filteredCategory
      }
    })

    setFilteredData(filteredResult)
  }
  const bg2 = useColorModeValue('bg-[#fff]', 'bg-[#1A202C]')
  return (
    <div>
      <Box
        hidden={showDrawer2}
        display={{ base: 'none', md: 'block' }}
        className="z-99 overflow-y-hidden fixed left-0 w-[19.1rem] h-screen flex flex-col px-6 border-[#CEC7C7] border-r-2"
        id="responsive"
      >
        <button
          className="z-50 ml-auto px-3 pt-2 rounded-md  focus:outline-none flex flex-1"
          onClick={() => setShowDrawer2(!showDrawer2)}
        >
          <ArrowLeftIcon className="h-6 w-6" />
        </button>
        <div
          id="chatWindow"
          className="mt-8 pb-80 w-full h-screen overflow-y-auto"
        >
          <div className="pb-8">
            <h1 className="text-lg font-semibold">Templates</h1>
            <p>Use the power of AI to facilitate your work.</p>
          </div>
        </div>
        <div
          style={{ zIndex: 99 }}
          className={`absolute bottom-0 left-0 right-0 px-4 py-2 ${bg2}`}
        >
          <div className="pt-2 pb-8 flex flex-row">
            <span className="mt-4 text-left font-semibold text-[#00A739] text-sm">
              {user?.email}
            </span>
            <div className="flex-grow" />
            <Menu>
              <MenuButton>
                <span className="text-[#00A739] text-3xl">...</span>
              </MenuButton>
              <MenuList className="text-sm">
                <MenuItem
                  onClick={(e) => {
                    e.preventDefault()
                    handleManageSubscription()
                  }}
                >
                  Pricing{' '}
                  <BanknotesIcon className="-mr-[8rem] h-5 w-5 flex flex-1" />
                </MenuItem>
                {/* <MenuItem onClick={() => router('/settings')}>
                  Settings{' '}
                  <Cog6ToothIcon className="-mr-[8rem] h-5 w-5 flex flex-1" />
                </MenuItem> */}
                <MenuItem
                  onClick={async () => {
                    await Cookies.remove('token')
                    await localStorage.removeItem('subscriptionDetails')
                    await Cookies.remove('email')
                    await Cookies.remove('selectedNamespace')
                    await Cookies.remove('selectedChatId')
                    await Cookies.remove('selectedFolder')
                    router('/signin')
                  }}
                >
                  Signout{' '}
                  <ArrowRightOnRectangleIcon className="-mr-[8rem] h-5 w-5 flex flex-1" />
                </MenuItem>
              </MenuList>
            </Menu>
          </div>
          <div
            onClick={toggleColorMode}
            className="text-center text-[#767676] justify-center mb-20 flex flex-row text-md rounded-md border border p-[1px] w-2/3 m-auto"
          >
            <button className="p-2 w-1/2">Light</button>
            <button className="p-2 bg-black text-white rounded-md w-1/2">
              Dark
            </button>
          </div>
        </div>
      </Box>
      <button
        className="z-50 ml-auto px-3 py-2 rounded-md  focus:outline-none "
        onClick={() => setShowDrawer2(!showDrawer2)}
        hidden={!showDrawer2}
      >
        <ArrowRightIcon className="h-6 w-6" />
      </button>
    </div>
  )
}

export default Main2
