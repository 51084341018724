import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import { Link } from 'react-router-dom'
import { useGoogleLogin } from '@react-oauth/google'
import { useEffect } from 'react'
import { Button, Input, useToast } from '@chakra-ui/react'
import Swal from 'sweetalert2'
import Popup from '../partials/Popup'

function ResetPassword() {
  const [email, setEmail] = useState(null)
  const [disabled, setDisabled] = useState(false)
  const [isPopupOpen, setPopupOpen] = useState(false)
  const toast = useToast()
  const router = useNavigate()
  const handlePasswordReset = async (e) => {
    e.preventDefault()

    if (email && email.includes('.') && email.includes('@')) {
      setDisabled(true)
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/resetPassword1`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email: email,
          }),
        },
      )

      const res2 = await res.json()
      console.log(res2)
      if (res2.error) {
        toast({
          title: res2.error,
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      } else if (res2.message) {
        toast({
          title: 'OTP Sent!',
          status: 'success',
          duration: 9000,
          isClosable: true,
        })
        setPopupOpen(true)
      } else {
        toast({
          title: 'Something went wrong!',
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      }
      setDisabled(false)
    } else {
      console.log('Error')
      toast({
        title: 'Something went wrong!',
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  }

  return (
    <section className="relative">
      <div className="mx-auto w-full h-full bg-[#adc4fe] flex">
        <div className="max-w-6xl m-auto px-4 py-24 sm:px-6">
          <div className="py-6 md:py-12 md:py-48 bg-[#adc4fe]">
            <div
              style={{ boxShadow: '5px 5px 0px 0px #003e39' }}
              className="max-w-6xl p-8 border-[1px] border-[#000] rounded-md bg-[#f4f4f4] mx-auto flex-1 py-6 md:py-24 "
            >
              {/* Page header */}
              <div className="max-w-6xl mx-auto text-center pb-12 space-y-4">
                <h1 className="font-extrabold text-5xl md:text-7xl">
                  Forgot Password?
                </h1>
                <p>Please enter your login information below</p>
              </div>

              {/* Form */}
              <div className="max-w-sm mx-auto">
                <div className="flex flex-wrap -mx-3 mb-4">
                  <div className="w-full px-3">
                    <Input
                      id="email"
                      type="email"
                      className="form-input w-full bg-white border-[1px] border-[#000]"
                      placeholder="Enter your email address"
                      required
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      bgColor={'white'}
                      borderColor={'black'}
                      borderWidth={'1px'}
                    />
                  </div>
                </div>

                <div className="flex flex-wrap -mx-3 mt-6">
                  <div className="w-full px-3">
                    <Button
                      disabled={disabled}
                      className="btn text-white rounded-md bg-black w-full"
                      isLoading={disabled}
                      onClick={(e) => {
                        handlePasswordReset(e)
                      }}
                      bg="black"
                      _hover={{ bg: 'black' }}
                    >
                      Recover
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <p className="my-6">© 2024 ScribeFlows</p>
          </div>
        </div>
      </div>
      {isPopupOpen && (
        <Popup
          title="Enter new password"
          inputs={['Password', 'OTP']}
          onCancel={() => setPopupOpen(false)}
          onConfirm={async (values) => {
            const { Password: password, OTP: otp } = values
            console.log(values)
            fetch(`${process.env.REACT_APP_BACKEND_URL}/api/resetPassword2`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                password,
                email,
                otp,
              }),
            })
              .then((response) => response.json())
              .then((res) => {
                console.log(res)
                if (res.message === 'Password Reset!') {
                  toast({
                    title: res.message,
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                  })
                  setTimeout(() => {
                    router('/signin')
                  }, 2000)
                } else if (res.error) {
                  toast({
                    title: res.error,
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                  })
                }
              })
              .catch((error) => {
                Swal.showValidationMessage(`Request failed: ${error}`)
              })

            setPopupOpen(false)
          }}
        />
      )}
    </section>
  )
}

export default ResetPassword
