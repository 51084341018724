import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { Button, Input, useToast } from '@chakra-ui/react';
import { FcGoogle } from 'react-icons/fc';
import { useGoogleLogin } from '@react-oauth/google'

function AdminSignin() {
  const navigate = useNavigate();
  const toast = useToast();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    const authToken = Cookies.get('adminToken');
    if (authToken) {
      navigate('/admin/dashboard'); // Redirect to admin dashboard if already logged in
    }
  }, [navigate]);

  const handleSignIn = async (e) => {
    e.preventDefault();

    if (email.includes('.') && email.includes('@') && password.length > 2) {
      setDisabled(true);
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/admin/signin`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email, password }),
        });

        const data = await response.json();

        if (data.token) {
          Cookies.set('adminToken', data.token, { expires: 7 });
          Cookies.set('email', data.email, { expires: 7 });
          setTimeout(() => {
            navigate('/admin/dashboard');
          }, 2000);
        } else {
          toast({
            title: 'Invalid credentials',
            status: 'error',
            duration: 9000,
            isClosable: true,
          });
        }
      } catch (error) {
        toast({
          title: 'An error occurred during sign-in',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      } finally {
        setDisabled(false);
      }
    } else {
      toast({
        title: 'Invalid email or password',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const handleSubmitGoogle = async (googleAccessToken) => {
    setDisabled(true)
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/signin`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ googleAccessToken }),
        },
      )

      const data = await response.json()
      console.log(data)

      if (data.error) {
        toast({
          title: data.error,
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      } else if (data.token) {
        Cookies.set('adminToken', data.token, { expires: 7 })
        Cookies.set('email', data.email, { expires: 7 })
        setTimeout(() => {
          navigate('/admin/dashboard')
        }, 2000)
      } else {
        toast({
          title: 'Something went wrong!',
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      }

      setDisabled(false)
    } catch (error) {
      toast({
        title: 'An error occured during signin',
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
      setDisabled(false)
    }
  }

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      handleSubmitGoogle(tokenResponse.access_token)
    },
  })

  return (
    <section className="min-h-screen bg-gray-100 flex items-center justify-center">
      <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-sm">
        <h1 className="text-2xl font-bold mb-4 text-center">Admin Sign-In</h1>



          <button
                      onClick={googleLogin}
                      className="btn px-0 text-black  bg-tranparent border-[1px] border-black font-bold w-full relative flex items-center rounded-md"
                    >
                      <FcGoogle className="flex w-5 h-5 justify-center align-center items-center mr-2" />
                      <span className="flex">Sign in with Google</span>
                    </button>
      </div>
    </section>
  );
}

export default AdminSignin;
