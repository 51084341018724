import React from 'react'
import { useRef, useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Header2 from '../components/Header2'
import {
  Button,
  useColorModeValue,
  useColorMode,
  useToast,
} from '@chakra-ui/react'
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Select,
} from '@chakra-ui/react'
import { useDisclosure } from '@chakra-ui/react'
import {
  ArrowUturnLeftIcon,
  Bars3Icon,
  Cog6ToothIcon,
} from '@heroicons/react/24/outline'
import Main2 from '../components/sidebar/Main2'
import TemplateChat from '../components/chatWindow/TemplateChat'
import Cookies from 'js-cookie'
import { FolderIcon } from '@heroicons/react/24/solid'
import useTemplateData from '../components/TemplateData'

function Template() {
  const { id } = useParams()
  const router = useNavigate()

  useEffect(() => {
    const authToken = Cookies.get('token')
    if (!authToken) {
      router('/signin')
    }
  }, [])

  const [user, setUser] = useState(null)
  const [showDrawer, setShowDrawer] = useState(false)
  const [showDrawer2, setShowDrawer2] = useState(false)
  const [search, setSearch] = useState('')

  const { colorMode, toggleColorMode } = useColorMode()
  const textColor = useColorModeValue('gray.200', 'white')
  const { isOpen, onOpen, onClose } = useDisclosure()
  const bg = useColorModeValue('bg-gray-50', 'bg-[#1E293B]')
  const [subscriptionDetails, setSubscriptionDetails] = useState(null)

  const { data } = useTemplateData();

  const [filteredData, setFilteredData] = useState([]);

  

  const [templateData, setTemplateData] = useState(null)
  const [formData, setFormData] = useState({})

  const toast = useToast()

  const messageListRef = useRef(null)

  const handleInputChange = (e, label) => {
    setFormData((prevData) => ({
      ...prevData,
      [label]: e.target.value,
    }))
  }

  const [messageState, setMessageState] = useState({
    messages: [],
    history: [],
  })

  const { messages, history } = messageState
  const [loading, setLoading] = useState(false)
  const textAreaRef = useRef(null)
  const [query, setQuery] = useState('')
  const [error, setError] = useState(null)

  const [namespaces, setNamespaces] = useState()
  const [selectedNamespace, setSelectedNamespace] = useState()
  const [isModalOpen, setModalOpen] = useState(false)
  const [foldersList, setFoldersList] = useState([])
  const [selectedChatId, setSelectedChatId] = useState()

  useEffect(() => {
    textAreaRef.current?.focus()
  }, [])
  useEffect(() => {
    // Scroll to the bottom when messages change
    messageListRef.current?.scrollTo(0, messageListRef.current?.scrollHeight)
  }, [messages]) // Scroll when messages change

  async function handleSubmit(e, continueResp) {
    e.preventDefault()
    setError(null)

    let question
    if (query) {
      question = query.trim()
      const currentDate = new Date()
      const formattedDate = currentDate.toISOString()
      setMessageState((state) => ({
        ...state,
        messages: [
          ...state.messages,
          {
            type: 'userMessage',
            message: question,
            date: formattedDate,
          },
        ],
      }))
    } else if (continueResp) {
      question = continueResp.trim()
      const currentDate = new Date()
      const formattedDate = currentDate.toISOString()
      setMessageState((state) => ({
        ...state,
        messages: [
          ...state.messages,
          {
            type: 'userMessage',
            message: 'Response Continued.',
            date: formattedDate,
          },
        ],
      }))
    } else {
      const currentDate = new Date()
      const formattedDate = currentDate.toISOString()
      setMessageState((state) => ({
        ...state,
        messages: [
          ...state.messages,
          {
            type: 'userMessage',
            message: 'Content generation requested!',
            date: formattedDate,
          },
        ],
      }))
    }
    setLoading(true)
    setQuery('')
    try {
      const authToken = await Cookies.get('token')

      // Create FormData to include the file
      const formData2 = new FormData()
      if (pdfFile) {
        formData2.append(
          'prompt',
          `${templateData?.prompt}. Please respond in HTML markdown format based on document.`,
        )
      } else {
        formData2.append(
          'prompt',
          `${templateData?.prompt}. Data : ${JSON.stringify(
            formData,
          )}. Please respond in HTML markdown format.`,
        )
      }

      formData2.append('history', JSON.stringify(messages))
      formData2.append('question', question)
      formData2.append('chatId', id)
      formData2.append('pdfFile', pdfFile) // Assume pdfFile is the file input state

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/templateChat`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
          body: formData2,
        },
      )

      const data = await response.json()

      if (data.error) {
        setError(data.error)
        toast({
          title: data.error,
          description: <span dangerouslySetInnerHTML={{ __html: data.desc }} />,
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
        setLoading(false)
      } else {
        const messageWords = data.text.split(' ')
        let currentIndex = 0
        let currentMessage = ''

        setMessageState((state) => ({
          ...state,
          messages: [
            ...state.messages,
            {
              type: 'apiMessage',
              message: '',
              date: data.date,
            },
          ],
        }))

        const interval = setInterval(() => {
          currentMessage += messageWords[currentIndex] + ' '

          setMessageState((state) => {
            const updatedMessages = [...state.messages]
            const lastMessageIndex = updatedMessages.length - 1
            updatedMessages[lastMessageIndex] = {
              ...updatedMessages[lastMessageIndex],
              message: currentMessage,
            }
            return {
              ...state,
              messages: updatedMessages,
            }
          })

          currentIndex++

          if (currentIndex >= messageWords.length) {
            clearInterval(interval)
            setLoading(false) // Set loading to false after the interval ends
          }
          localStorage.removeItem('subscriptionDetails')
        }, 200)
      }

      messageListRef.current?.scrollTo(0, messageListRef.current?.scrollHeight)
    } catch (error) {
      setLoading(false)
      console.error('Error fetching data:', error)
      if (error) {
        console.error('Server responded with:', error)
      }
      setError('An error occurred while fetching the data. Please try again.')
    }
  }

  async function clearChat() {
    try {
      const authToken = await Cookies.get('token')

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/clearChat`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify({ chatId: id }),
        },
      )

      if (!response.ok) {
        throw new Error('Failed to clear chat')
      }

      const data = await response.json()
      setMessageState({
        messages: [],
        history: [],
      })
    } catch (err) {
      console.error(err)
    }
  }

  const handleEnter = (e) => {
    if (e.key === 'Enter' && query) {
      handleSubmit(e)
    } else if (e.key === 'Enter') {
      e.preventDefault()
    }
  }

  const [pdfFile, setPdfFile] = useState(null)

  const handleFileChange = (e) => {
    const file = e.target.files[0]
    if (file) {
      setPdfFile(e.target.files[0])
    } else {
      alert('Please upload a valid PDF file')
    }
  }

  useEffect(() => {
    async function fetchMessages() {
      try {
        const authToken = await Cookies.get('token')

        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/messages`, // Replace 'YOUR_CHAT_ID' with the actual chat ID or pass it dynamically
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          },
        )

        if (!response.ok) {
          throw new Error('Failed to fetch messages')
        }

        const data = await response.json()

        console.log(data)

        const filteredMessages = data.filter((message) => message.chatId === id)

        setMessageState({
          messages: [],
          history: [],
        })

        // Assuming `data` is an array of messages
        filteredMessages.forEach((message) => {
          setMessageState((state) => ({
            ...state,
            messages: [
              ...state.messages,
              {
                type: 'apiMessage',
                message: message.content, // or any other field that contains the message text
                date: message.createdAt,
              },
            ],
          }))
        })
      } catch (err) {
        setError(err.message)
        console.error(err)
      }
    }

    fetchMessages()
  }, [])

  useEffect(() => {
    // Search for the template with the matching id
    let templateData2 = null
    if (data) {
    Object.entries(data).forEach(([categoryName, category]) => {
      Object.entries(category).forEach(([templateName, templateDataObj]) => {
        if (
          templateDataObj.id === Number(id) &&
          templateDataObj.plan &&
          subscriptionDetails &&
          templateDataObj.plan.includes(subscriptionDetails.planId)
        ) {
          templateData2 = {
            category: categoryName,
            ...templateDataObj,
          }
        }
      })
    })
    setTemplateData(templateData2)
  }
  }, [subscriptionDetails, data])

  return (
    <div className="h-[100vh] lg:h-screen lg:overflow-y-hidden">
      <Header2
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        setUser={setUser}
        current={2}
        user={user}
        subscriptionDetails={subscriptionDetails}
        setSubscriptionDetails={setSubscriptionDetails}
      />
      <div className={`flex lg:pb-40`}>
        <Main2
          showDrawer={showDrawer}
          user={user}
          router={router}
          setShowDrawer={setShowDrawer}
          toggleColorMode={toggleColorMode}
          data={data}
          setFilteredData={setFilteredData}
          search={search}
          selected={templateData?.category}
          setShowDrawer2={setShowDrawer2}
          showDrawer2={showDrawer2}
        />
        <main
          className={`z-1 pb-2 md:pb-20 flex-1 mx-auto w-full ${
            !showDrawer2 ? 'md:pl-72' : ''
          } lg:h-screen`}
        >
          {/* Mobile Drawer */}
          <button
            className="z-50 md:hidden ml-auto px-3 py-2 rounded-md  focus:outline-none"
            onClick={() => setShowDrawer(!showDrawer)}
          >
            <Cog6ToothIcon className="h-6 w-6" />
          </button>
          <button
            className="z-50 md:hidden px-3 py-2 rounded-md  focus:outline-none flex-none ml-auto"
            onClick={onOpen}
          >
            <Bars3Icon className="h-6 w-6" />
          </button>

          <div className={`sm:px-6 lg:px-4  lg:h-screen`}>
            <div
              hidden={templateData == null}
              className="w-full flex flex-col lg:flex-row m-auto gap-x-4 lg:h-screen"
            >
              <div className={`flex flex-col w-full p-1 ${bg}`}>
                <div
                  className={`flex flex-col w-full m-auto space-y-6 p-4 lg:h-screen pb-40 lg:overflow-y-scroll`}
                >
                  <Button onClick={() => router(-1)} className="w-16 p-2">
                    <ArrowUturnLeftIcon className="w-5" />
                  </Button>
                  <div>
                    <h1 className="text-lg font-semibold m-auto text-center pb-6">
                      {templateData?.header}
                    </h1>
                  </div>
                  <div>
                    <Box>
                      {templateData && templateData.inputs && templateData.inputs.length > 0 ? templateData.inputs.map((inputField, index) => (
                        <Box className="space-y-4" key={index} mb={4}>
                          {Object.entries(inputField).map(([label, field]) => (
                            <FormControl key={label}>
                              <FormLabel>{label}</FormLabel>
                              {field.type === 'textarea' ? (
                                <Textarea
                                  placeholder={field.placeholder}
                                  minHeight={100}
                                  value={formData[label]}
                                  onChange={(e) => handleInputChange(e, label)}
                                />
                              ) : field.type === 'select' ? (
                                <Select
                                  placeholder="Select an option"
                                  onChange={(e) => handleInputChange(e, label)}
                                  value={formData[label]}
                                >
                                  {field.placeholder.map((option, index) => (
                                    <option key={index} value={option}>
                                      {option}
                                    </option>
                                  ))}
                                </Select>
                              ) : field.type === 'file' ? (
                                <input
                                  type="file"
                                  accept="application/pdf"
                                  onChange={(e) => handleFileChange(e, 'pdf')}
                                />
                              ) : (
                                <Input
                                  type={field.type}
                                  placeholder={field.placeholder}
                                  onChange={(e) => handleInputChange(e, label)}
                                  value={formData[label]}
                                />
                              )}
                            </FormControl>
                          ))}
                        </Box>
                      )) : null}
                    </Box>
                  </div>
                </div>
                <div
                  className={`flex justify-end sticky bottom-0 ${bg} right-0  p-4`}
                >
                  <Button className="mt-4 mr-2" onClick={clearChat}>
                    Clear
                  </Button>
                  <Button
                    className="mt-4"
                    colorScheme="green"
                    onClick={handleSubmit}
                    isLoading={loading}
                    disabled={loading}
                  >
                    Generate
                  </Button>
                </div>
              </div>

              <div className="w-full overflow-y-hidden">
                <TemplateChat
                  user={user}
                  handleSubmit={handleSubmit}
                  handleEnter={handleEnter}
                  setMessageState={setMessageState}
                  messages={messages}
                  loading={loading}
                  query={query}
                  setQuery={setQuery}
                  error={error}
                  messageListRef={messageListRef}
                  textAreaRef={textAreaRef}
                />
              </div>
            </div>
            <div hidden={templateData != null} className="p-4">
              Please upgrade to get this template.
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}

export default Template
