import React, { useEffect } from 'react'
import ReactMarkdown from 'react-markdown'
import {
  ClipboardDocumentCheckIcon,
  UserCircleIcon,
} from '@heroicons/react/24/solid'
import { Button, useColorModeValue } from '@chakra-ui/react'

import TemplateChatForm from './TemplateChatForm'

function TemplateChat({
  user,
  handleSubmit,
  handleEnter,
  setMessageState,
  messages,
  loading,
  query,
  setQuery,
  error,
  messageListRef,
  textAreaRef,
}) {
  const bg = useColorModeValue('bg-[#FFF2F2]', 'bg-[#1E293B]')
  const bg2 = useColorModeValue('bg-[#fff]', 'bg-[#1A202C]')

  const currDate = new Date()

  const formattedDate = currDate.toLocaleString('en-US', {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
    day: 'numeric',
    month: 'short',
  })

  const [rest, time] = formattedDate.split(',')
  const [month, day] = rest.trim().split(' ')

  const currFormat = `${time}, ${day} ${month}`

  useEffect(() => {
    // Scroll to the bottom when messages change
    messageListRef.current?.scrollTo(0, messageListRef.current?.scrollHeight)
  }, [messages]) // Scroll when messages change

  return (
    <>
      <div className={`flex flex-row gap-4 h-screen`}>
        <div
          id="chatWindow"
          className={`mx-auto overflow-y-scroll md:h-screen w-full m-auto`}
        >
          <div
            id="chatWindow"
            ref={messageListRef}
            className="mx-auto h-[80vh] flex flex-col gap-4 w-full m-auto overflow-y-scroll pb-8"
          >
            <div className="mt-2">
              <div className="text-[0.8rem] flex flex-row">
                <ClipboardDocumentCheckIcon className="h-5 w-5 mr-2" />
                {currFormat ? currFormat : null}
              </div>
              <div
                key={`chatMessage-0`}
                className={`py-2 px-4 w-3xl ${bg} self-start rounded-br-3xl rounded-tr-3xl rounded-tl-xl`}
              >
                <div linkTarget="_blank">
                  Hey {user?.fName}! Try generating some content based on the
                  inputs I will give you a best output needed!
                </div>
              </div>
            </div>
            {messages && messages.length > 0
              ? messages.map((message, index) => {
                  let className
                  if (message.type === 'apiMessage') {
                    className = `self-start rounded-br-3xl ${bg} rounded-tr-3xl rounded-tl-xl w-3xl`
                  } else {
                    className =
                      loading && index + 1 === messages.length - 1
                        ? 'text-[#888888] w-3xl self-end pb-30'
                        : 'text-[#888888] w-3xl self-end'
                  }
                  if (message.type === 'apiMessage') {
                    const date = new Date(message.date)

                    const formattedDate = date.toLocaleString('en-US', {
                      hour: 'numeric',
                      minute: '2-digit',
                      hour12: true,
                      day: 'numeric',
                      month: 'short',
                    })

                    const [rest, time] = formattedDate.split(',')
                    const [month, day] = rest.trim().split(' ')

                    const finalFormat = `${time}, ${day} ${month}`

                    // Removing ```html from the start
                    const str = message.message.replace('```html', '')

                    // Removing ``` from the end
                    const str2 = str.replace('```', '')
                    return (
                      <>
                        <div className="flex justify-between items-center -mb-2">
                          <div className="text-[0.8rem] flex flex-row">
                            <ClipboardDocumentCheckIcon className="h-5 w-5 mr-2" />
                            {finalFormat}
                          </div>
                        </div>
                        <div
                          key={`chatMessage-${index + 1}`}
                          className={`py-2 px-4 ${className}`}
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: str2,
                            }}
                          />
                        </div>
                        <div className="flex flex-row gap-x-2">
                          <Button
                            size="xs"
                            className="text-[0.8rem] text-gray-500 hover:text-gray-700 w-[5rem] p-2 -mt-3"
                            onClick={() => {
                              navigator.clipboard
                                .writeText(message.message)
                                .then(() => {
                                  console.log('Text copied!')
                                })
                                .catch((error) => {
                                  console.error('Failed to copy text:', error)
                                })
                            }}
                            isLoading={loading}
                            hidden={loading}
                          >
                            Copy
                          </Button>
                          <Button
                            size="xs"
                            className="text-[0.8rem] text-gray-500 hover:text-gray-700 w-[5rem] p-2 -mt-3"
                            onClick={(e) => {
                              handleSubmit(
                                e,
                                `Continue previous response where you left i.e. ${message.message}`,
                              )
                            }}
                            isLoading={loading}
                            hidden={loading}
                          >
                            Continue
                          </Button>
                        </div>
                      </>
                    )
                  } else {
                    const date = new Date(message.date)

                    const formattedDate = date.toLocaleString('en-US', {
                      hour: 'numeric',
                      minute: '2-digit',
                      hour12: true,
                      day: 'numeric',
                      month: 'short',
                    })

                    const [rest, time] = formattedDate.split(',')
                    const [month, day] = rest.trim().split(' ')

                    const finalFormat = `${time}, ${day} ${month}`
                    return (
                      <div
                        key={`chatMessage-${index + 1}`}
                        className={`py-2 px-4 text-right max-w-2xl ${className}`}
                      >
                        <div className="flex flex-row">
                          <div className="flex flex-col">
                            <div className="text-[0.8rem] text-right">
                              {finalFormat}
                            </div>
                            <ReactMarkdown>
                              {message.message}
                            </ReactMarkdown>
                          </div>

                          <div>
                            {user && user.avatar && user.avatar.length > 10 ? (
                              <img
                                alt="Avatar"
                                className="rounded-full h-[3rem] w-[3rem] m-2 -mt-1"
                                src={user?.avatar}
                              />
                            ) : (
                              <UserCircleIcon className="m-2 -mt-1 h-[3rem] w-[3rem]" />
                            )}
                          </div>
                        </div>
                      </div>
                    )
                  }
                })
              : null}
          </div>
          <div
            className={`sticky ${bg2} bottom-0 transform  relative w-full lg:pb-24`}
          >
            <TemplateChatForm
              loading={loading}
              error={error}
              query={query}
              textAreaRef={textAreaRef}
              handleEnter={handleEnter}
              handleSubmit={handleSubmit}
              setQuery={setQuery}
              messages={messages}
              setMessageState={setMessageState}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default TemplateChat
