import React from 'react';
import { Box, Button, Flex, Text } from "@chakra-ui/react";
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

const AdminNavbar = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    // Clear the admin token
    Cookies.remove('adminToken');
    // Redirect to login or home page
    navigate('/admin/signin');
  };

  return (
    <Flex
      as="nav"
      p={4}
      borderBottom="1px"
      borderColor="gray.200"
      justifyContent="space-between"
      alignItems="center"
    >
      <Text fontSize="xl" fontWeight="bold">
        Admin Dashboard
      </Text>
      <Button onClick={handleLogout} colorScheme="red">
        Logout
      </Button>
    </Flex>
  );
};

export default AdminNavbar;
